<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div slot="header">
      <h5>{{ $route.meta.title }}</h5>
    </div>

    <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
      <div class="row">
        <div class="col-md-8">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <el-avatar
              class="pofile-avatar pointer"
              @click="coverDialogVisible = true"
              :src="
                user.avatar
                  ? viewImg(user.avatar)
                  : require('../../assets/images/userAvatar.png')
              "
            ></el-avatar>
            <el-button
              type="primary"
              class="mt-20"
              size="small"
              @click="coverDialogVisible = true"
              >上传头像</el-button
            >
          </div>
          <el-form-item class="mt-20" label="昵称" prop="nickname">
            <el-input
              v-model="formData.nickname"
              placeholder="请输入昵称"
              :maxlength="15"
              show-word-limit
              clearable
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>

          <el-form-item class="mt-20" label="性别" prop="gender">
            <el-radio v-model="formData.gender" :label="1">男</el-radio>
            <el-radio v-model="formData.gender" :label="2">女</el-radio>
            <el-radio v-model="formData.gender" :label="0">保密</el-radio>
          </el-form-item>
          <el-form-item class="mt-20" label="介绍" prop="description">
            <el-input
              v-model="formData.description"
              placeholder="个人介绍"
              :maxlength="200"
              show-word-limit
              type="textarea"
              clearable
              autosize
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-md-12"></div>
      </div>
      <div class="row mt-20">
        <div class="col-md-8">
          <div class="text-center">
            <el-button type="success" @click="submitForm()">保存</el-button>
          </div>
        </div>
      </div>
    </el-form>

    <el-dialog
      title="上传头像"
      :visible.sync="coverDialogVisible"
      width="60%"
      append-to-body
    >
      <div class="d-flex justify-content-center align-items-center flex-column">
        <ImageCroper
          ref="imageCroper"
          v-if="coverDialogVisible"
          @success="uploadImage"
          :ratio="1 / 1"
          round
        ></ImageCroper>
        <el-button type="primary" @click="cropImage">上传</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import ImageCroper from "@/components/imageCroper";
export default {
  components: { ImageCroper },
  data() {
    return {
      coverDialogVisible: false,
      formData: {
        nickname: "",
        gender: "",
        description: "",
      },
      rules: {
        nickname: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.formData = {
      nickname: this.user.nickname,
      gender: this.user.gender,
      description: this.user.description,
    };
  },
  mounted() {},
  methods: {
    cropImage() {
      this.$refs.imageCroper.crop();
    },
    uploadImage(file) {
      if (!file) {
        return;
      }
      let param = new FormData(); //创建form对象
      param.append("attach", file, "crop.jpg"); //通过append向form对象添加数据

      this.$api.ucenter.User.avatar(param).then((res) => {
        res.data.avatar = res.data.avatar
          ? res.data.avatar + "?r=" + this.$utils.getID()
          : null;

        this.$store.dispatch("setUserInfo", res.data);

        this.coverDialogVisible = false;
      });
    },

    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;

        // TODO 提交表单
        this.loading = true;
        this.$api.ucenter.User.saveUserInfo(this.formData)
          .then((res) => {
            this.$store.dispatch("setUserInfo", res.data.user);
            this.loading = false;

            this.$utils.success("保存成功");
          })
          .catch((err) => {
            console.log("err:", err);
            this.loading = false;
          });
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.pofile-avatar {
  width: 100px;
  height: 100px;
}
</style>
